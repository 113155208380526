@import '../../styles/variables.scss';

.MainContainer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;

    .TextContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 800px;

        h1 {
            color: $color-primary;
            font-size: 5rem;
            font-weight: 700;
            letter-spacing: 0.6rem;
            text-align: center;
        }
    }
}

.MobileMainContainer {
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;

    .MobileTextContainer {
        display: flex;
        height: 95vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 95vw;

        h1 {
            color: $color-primary;
            font-size: 2.5rem;
            font-weight: 700;
            letter-spacing: 0.4rem;
            text-align: center;
        }
    }
}

.PortfolioContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: left;

    .CenterText {
        text-align: center;
    }

    .Row {
        display: flex;
        flex-direction: row;
    }

    .Col {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
    }

    .StoreImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .MyImg {
        margin: 4px;
    }
}

.MobilePortfolioContainer {
    display: flex;
    width: 99%;
    height: 99%;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;

    .MobileCenterText {
        text-align: center;
    }

    .Row {
        display: flex;
        flex-direction: row;
    }

    .Col {
        display: flex;
        flex-direction: column;
        margin: 0 20px 0 0;
    }

    .MobileStoreImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .MobileMyImg {
        margin: 4px;
    }
}

