@import '../../styles/variables';

.AboutButtonUp {
    position: relative;
    top: 0vh;
    // left: 50%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: $color-primary;
    text-align: center;
    display: inline-block;
    font-size: 4rem;
    cursor: pointer;
    animation: pulse 2s infinite;
    &:hover {
        color: darken($color-primary, 20%);
    }
}

.AboutButtonDown {
    position: relative;
    top: 80vh;
    // left: 50%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: $color-primary;
    text-align: center;
    display: inline-block;
    font-size: 4rem;
    cursor: pointer;
    animation: pulse 2s infinite;
    &:hover {
        color: darken($color-primary, 20%);
    }
}

.MobileAboutButtonUp {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    position: relative;
    bottom: 5vh;
    left: 65vw;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: $color-primary;
    text-align: center;
    display: inline-block;
    font-size: 3rem;
    cursor: pointer;
    animation: pulse 2s infinite;
    &:hover {
        color: darken($color-primary, 20%);
    }
}

.MobileAboutButtonDown {
    position: relative;
    top: 80vh;
    // left: 50%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: $color-primary;
    text-align: center;
    display: inline-block;
    font-size: 3rem;
    animation: pulse 2s infinite;
    &:hover {
        color: darken($color-primary, 20%);
    }
}

.AboutButtonRotate {
    transform: rotate(180deg);
    transition: transform 0.5s ease-in-out;
}

@keyframes pulse {
    0% { 
        transform: scale(1); 
        filter: brightness(100%);
    }
    50% { 
        transform: scale(1.3); 
        filter: brightness(30%);
    }
    100% { 
        transform: scale(1); 
        filter: brightness(100%);
    }
}