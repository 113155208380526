@import './styles/variables.scss';

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-height: 100vh;
    overflow: hidden;
    scrollbar-width: none;
}