@import '../../styles/variables.scss';

.MainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .TheO {
        width: 55px;
        height: 55px;
        background-color: $color-primary;
        border-radius: 50%;
        margin-right: 0.5rem;
        transition: all 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            border: 3px solid lighten($color-primary, 100%);
        }
    }

    .TheOExpanded {
        display: flex;
        background-color: darken($color-background, 3%);
        border-radius: 20%;
        margin-right: 0.5rem;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        scrollbar-width: none;
    
        .ExpandedText {
            width: 100%;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 20%;
            color: $color-primary;
            overflow: hidden;
            scrollbar-width: none;
            white-space: wrap;
            word-wrap: break-word;
        }
    }
}

.MobileMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    transition: all 0.5s ease-in-out;

    h1 {
        margin: 8px 0;
    }

    .MobileExpandingContainer {
        width: 100%;
        transition: all 0.5s ease-in-out;
        height: 0px;
    }

    .MobileExpandingContainerExpanded {
        width: 100%;
        transition: all 0.5s ease-in-out;
        background-color: darken($color-background, 3%);
        border-radius: 20px;
    }

    .MobileExpandedText {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-primary;
        overflow: hidden;
        scrollbar-width: none;
        white-space: wrap;
        word-wrap: break-word;
    }
}

