.contentContainer {
    width: 90vw;
    max-width: 1200px;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    scrollbar-width: none;

}